import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

function Video({ url, type, className, forwardRef, onPlay, onPause, poster }) {
  let classList = ["shared__video"];
  const videoRef = forwardRef || useRef();
  const [playing, setPlaying] = useState(true);
  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );

  useEffect(() => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.play();
        onPlay();
      } else {
        videoRef.current.pause();
        onPause();
      }
    }
  }, [playing]);

  const handleClick = () => {
    if (videoRef.current) {
      setPlaying(!playing);
    }
  };
  return (
    <video
      className={classList.join(" ")}
      ref={videoRef}
      onClick={handleClick}
      poster={poster}
      autoPlay
      muted
      loop
      playsInline
      preload="auto">
      <source src={url} type={type} />
    </video>
  );
}

Video.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  poster: PropTypes.string,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

Video.defaultProps = {
  className: "",
  forwardRef: null,
  onPlay: () => {},
  onPause: () => {},
  poster: null,
};

Video.displayName = "Video";

export default Video;
